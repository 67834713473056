<template>
  <b-row>
    <b-col cols="12">
      <div style="text-align: center">
        <div class="comercial-title">{{ $t("COMERCIAL.ABA") }}</div>
      </div>
    </b-col>
    <b-col cols="12"
      ><span
        class="display:'none'"
        :onclick="
          openInNewTab(
            'https://app.powerbi.com/groups/ed5e27e5-7eca-44f8-951b-e130cf9739e9/list?experience=power-bi'
          )
        "
      >
        .
      </span></b-col
    >
  </b-row>
</template>

<script>
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  name: "Index",
  data() {
    return {};
  },
  methods: {
    openInNewTab(url) {
      window.open(url, "_blank");
    },
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.DESEMPENHO_COMERCIAL") },
    ]);
    this.openInNewTab(
      "https://app.powerbi.com/groups/ed5e27e5-7eca-44f8-951b-e130cf9739e9/list?experience=power-bi"
    );
  },
};
</script>

<style scoped>
.comercial-title {
  font-size: 18px;
  font-weight: 600;
  color: #343a40;
  margin: auto;
}
</style>
